import createClient, { type Middleware } from 'openapi-fetch';
import * as Sentry from '@sentry/vue';
import type { paths } from '~/models/Api';
import { getErrorMessage } from '~/models/ApiResponse';

/**
 * We're using "openapi-fetch" instead of Nuxt's built-in "ofetch" because "ofetch"
 * doesn't fully comply with the official fetch specification
 * (see: https://github.com/openapi-ts/openapi-typescript/issues/1691#issuecomment-2183578979).
 * Additionally, "ofetch" is incompatible with "openapi-typescript" types
 * (see: https://github.com/unjs/ofetch/issues/406).
 *
 * If either of these issues are resolved in the future, we should refactor this
 * to ensure consistent fetch functions across the application.
 */

export default defineNuxtPlugin({
	dependsOn: ['sentry-client'],
	name: 'api',
	setup: () => {
		const supabaseClient = useSupabaseClient();
		const supabaseSession = useSupabaseSession();

		/**
         * We want to explicity throw an error to ensure Tanstack Query works
         */
		const throwOnError: Middleware = {
			async onResponse({ response }) {
				if (response.status >= 400) {
					const body = response.headers.get('content-type')?.includes('json')
						? await response.clone().json()
						: await response.clone().text();

					const error = new Error(getErrorMessage(body), { cause: body });

					Sentry.withScope((scope) => {
						scope.setContext('Api Error Response', { body });
						scope.setFingerprint(['Submission', 'Apply']);
						Sentry.captureException(error);
					});

					throw error;
				}
				return undefined;
			},
			async onRequest({ request }) {
				const requestBody = request.headers.get('content-type')?.includes('json')
					? await request.clone().json().catch(() => ({}))
					: await request.clone().text().catch(() => '');

				Sentry.setContext('Api Request Body', { body: requestBody });

				return undefined;
			},
		};

		const authMiddleware: Middleware = {
			async onResponse({ response }) {
				if (response.status === 401) {
					const result = await supabaseClient.auth.signOut();

					if (result.error) {
						// @ts-expect-error: signOut is not working when the user has an invalid session. It's throwing an 403 and is not deleting the cookies or local storage. Therefore we try to do this manually. Unfortunately "storageKey" is a private member of client. (https://github.com/supabase/auth-js/pull/894)
						localStorage.removeItem(supabaseClient.storageKey);
					}

					await navigateTo('/login');
				}
			},
			onRequest({ request }) {
				const accessToken = supabaseSession.value?.access_token;

				if (!accessToken) {
					return;
				}

				request.headers.set('Authorization', `Bearer ${accessToken}`);

				return request;
			},
		};

		const { public: { apiBaseUrl } } = useRuntimeConfig();

		const api = createClient<paths>({
			baseUrl: apiBaseUrl,
		});

		api.use(authMiddleware);
		api.use(throwOnError);

		// Expose to useNuxtApp().$api
		return {
			provide: {
				api,
			},
		};
	},
});
