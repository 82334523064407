import revive_payload_client_pD2ODGoAgK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ze2X7bOOeS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XaMcROTnKt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_tzmH66vnPZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_LJVU1Xuduz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_eTQuo5iHOO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xBjy0tPbXb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jII125b0lG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_bIW1NbP3jr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_IfcldbFAwz from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_rzev2x9c91 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_KKYY1cOdDj from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_RWtpCJ0O6d from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_jdFauFWzI1 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_rz3gPkYs9p from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_n9lNd8fk6K from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_redirect_7YxV930Bgu from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import api_client_hHF76B0Hrp from "/opt/build/repo/plugins/api.client.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import userApiMapping_client_vfoioqdINo from "/opt/build/repo/plugins/userApiMapping.client.ts";
import userLocaleMapping_client_db1Wddiegg from "/opt/build/repo/plugins/userLocaleMapping.client.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
import zod_q7cfI0sipZ from "/opt/build/repo/plugins/zod.ts";
export default [
  revive_payload_client_pD2ODGoAgK,
  unhead_ze2X7bOOeS,
  router_XaMcROTnKt,
  supabase_client_tzmH66vnPZ,
  payload_client_LJVU1Xuduz,
  navigation_repaint_client_eTQuo5iHOO,
  check_outdated_build_client_xBjy0tPbXb,
  chunk_reload_client_jII125b0lG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bIW1NbP3jr,
  switch_locale_path_ssr_IfcldbFAwz,
  i18n_rzev2x9c91,
  slideovers_KKYY1cOdDj,
  modals_RWtpCJ0O6d,
  colors_jdFauFWzI1,
  plugin_client_rz3gPkYs9p,
  plugin_n9lNd8fk6K,
  auth_redirect_7YxV930Bgu,
  api_client_hHF76B0Hrp,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  userApiMapping_client_vfoioqdINo,
  userLocaleMapping_client_db1Wddiegg,
  vue_query_wrmMkNpEpe,
  zod_q7cfI0sipZ
]